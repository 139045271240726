<template>
  <div class="wrap rowd space-between w100">
    <div class="card1 columnd space-around">
      <span class="f28-700 c-green"> 222 </span>
      <span class="f28-700 c-white"> Users </span>
    </div>
    <div class="card2 columnd space-around">
      <span class="f28-700 c-green"> 784,333 USD </span>
      <span class="f28-700 c-white"> Trades </span>
    </div>
    <div class="card3 columnd space-around">
      <span class="f28-700 c-green"> 445 </span>
      <span class="f28-700 c-white"> Trades </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutCards",
};
</script>

<style lang="scss" scoped>
.wrap {
  gap: 20px;
  flex-wrap: wrap;
}
.card1 {
  background: url("../../../assets/Icons/GreenCard.svg");
  max-width: 384px;
  width: 100%;
  height: 218px;
  background-size: cover;
}
.card2 {
  background: url("../../../assets/Icons/OrangeCard.svg");
  max-width: 384px;
  width: 100%;
  height: 218px;
  background-size: cover;
}
.card3 {
  background: url("../../../assets/Icons/BlueCard.svg");
  max-width: 384px;
  width: 100%;
  height: 218px;
  background-size: cover;
}
</style>
