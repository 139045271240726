<template>
  <div class="homepage container columnd">
    <Banner />
    <Cards />
    <Trading />
    <Start />
  </div>
</template>

<script>
import Banner from "./Banner.vue";
import Cards from "./Cards.vue";
import Trading from "./Trading.vue";
import Start from "./Start.vue";
export default {
  name: "AboutUs",
  components: {
    Banner,
    Cards,
    Trading,
    Start,
  },
};
</script>

<style lang="scss" scoped>
.homepage {
  gap: 96px;
}
</style>
