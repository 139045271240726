<template>
  <div class="trading rowd space-between w100">
    <inline-svg
      class="featuresIcons"
      :src="require('../../../assets/Icons/featuresIcons.svg')"
    />
    <div class="columnd texts text-start">
      <span class="f22-700 c-lightOrange"> P2P Trading </span>
      <p class="f14-100 c-darkGrey">
        Behind KuCoin are two tech geeks who were early blockchain adopters.
        Having started coding at the age of 8 and founded his first startup at
        the age of 16, Michael immediately started to mine BTC when he heard
        about Bitcoin 2012 from his boss Eric in 2021. But when he tried to sell
        some BTC on Mt. Gox, he discovered that what was the world's largest
        platform at the time was difficult for beginners to use. As the adoption
        of blockchain continued, Michael and Eric realized that it was reshaping
        the financial system into one that would not only serve richest few, but
        everyone in the world - even the less educated, unemployed, and
        unbanked. By the end of 2013, they wrote KuCoin's first pieces of code
        in a cafe, beginning the journey of a People's Exchange that would allow
        everyone to get involved with crypto.
      </p>
      <button class="secondary-btn dflex align-center">
        <span> ٰView All Offers </span>
        <inline-svg :src="require('../../../assets/Icons/longArrow.svg')" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Trading",
};
</script>

<style lang="scss" scoped>
.secondary-btn {
  margin-top: 30px;
  border: none !important;
  max-width: 180px;
  max-height: 38px;
  align-self: flex-end;
  gap: 8px;
  svg {
    fill: var(--darkOrange);
  }
  &:hover {
    svg {
      fill: var(--white);
    }
  }
}
.texts {
  width: 40%;
}
.featuresIcons {
  svg {
    width: 100%;
  }
}
.trading {
  padding-top: 100px;
  gap: 40px;
  flex-wrap: wrap;
}
@media only screen and(max-width:800px) {
  .trading {
    justify-content: center;
    align-items: center;
  }
  .texts {
    width: 100%;
  }
}
</style>
