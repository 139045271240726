<template>
  <div class="start-container justify-center align-center w100 columnd">
    <h1 class="f36-700 c-lightOrange">Start Trading</h1>
    <p class="f18-400 c-ddGrey mw">
      Radindev trading platform for pere to pere exchanges in fastest way
    </p>
    <button class="primary-btn">Submit</button>
  </div>
</template>

<script>
export default {
  name: "Start",
};
</script>

<style lang="scss" scoped>
.start-container {
  gap: 16px;
  height: 120vh;
  background-image: url("../../../assets/Background/Circle.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.primary-btn {
  max-width: 170px;
}
.mw {
  max-width: 330px;
}
@media only screen and(max-width:1024px) {
  .start-container {
    background-image: none !important;
  }
}
</style>
