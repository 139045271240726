<template>
  <div class="banner w100 rowd space-between align-center">
    <div class="text-start banner-title columnd">
      <h1 class="c-lightOrange f36-700">Our Story</h1>
      <h4 class="f18-400 c-ddGrey">
        Radindev trading platform for pere to pere exchanges in fastest way
      </h4>
      <p class="c-medGrey f14-400">
        Behind KuCoin are two tech geeks who were early blockchain adopters.
        Having started coding at the age of 8 and founded his first startup at
        the age oft the time was difficult for beginners to use.
      </p>
    </div>
    <inline-svg
      class="featuresIcons"
      :src="require('../../../assets/Icons/featuresIcons.svg')"
    />
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style lang="scss" scoped>
.featuresIcons {
  transform: translateY(-120px);
}
.banner-title {
  gap: 12px;
  max-width: 350px;
  transform: translateX(100px);
}
.banner {
  position: relative;
  height: 900px;
  gap: 50px;
  &::before {
    content: " ";
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../../../assets/Background/PublicBanner.svg");
    background-size: 100%;
    z-index: -1;
    transform: translate(10px, -60px) scale(1.2, 1.01);
    background-repeat: no-repeat;
  }
}
@media only screen and(max-width:1024px) {
  .banner::before {
    content: none;
  }
  .banner {
    flex-direction: column !important;
  }
  .banner-title {
    transform: unset !important;
    display: unset !important;
  }
  .find-order {
    transform: unset !important;
  }
}
</style>
